.container {
  background-color: #f1f1f1;
  padding-left: 90px;
  padding-right: 90px;
  padding-top: 50px;
  padding-bottom: 50px;
}

.secondContainer {
  margin-left: 100px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
}

.logo {
  width: 130px;
}

.row {
  display: flex;
  flex-direction: column;
}

.link {
  color: #7e7f84;
  text-decoration: none;
  font-size: 12px;
  margin-bottom: 20px;
}

.link:hover {
  text-decoration: underline;
}

.iconContainer {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  color: #7e7f84;
  font-size: 16px;
}

.text {
  color: #7e7f84;
  font-size: 12px;
  margin-bottom: 20px;
  margin-top: 0;
}

@media screen and (max-width: 768px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
    align-items: center;
  }

  .secondContainer {
    margin-left: 0;
    display: grid;
    grid-template-columns: 1fr;
  }

  .row {
    text-align: center;
    align-items: center;
  }

  .link {
    margin-left: 0;
  }
}
