.container {
  display: flex;
  flex-direction: column;
  min-height: 200vh;
}

.content {
  display: flex;
  flex-direction: column;
}

.navbar {
  background-color: #f1f1f1;
  padding: 8px;
  width: 107px;
  flex: 0 0 auto;
}

.routes {
  flex: 1; /* Se expandirá para ocupar todo el espacio restante */
}
