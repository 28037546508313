@font-face {
  font-family: "Sansation Regular";
  src: url("../../fonts/Sansation-Regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Sansation Bold";
  src: url("../../fonts/Sansation-Bold-webfont.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}
@media screen and (min-width: 1025px) {
  .top_image {
    width: 100%;
    height: auto;
  }

  .container {
    background-color: #f1f1f1;
    height: auto;
  }

  .title {
    margin-right: -60px;
    position: relative;
    z-index: 1;
    color: #f49728;
    font-size: 50pt;
    font-weight: 400;
    margin-bottom: 10px;
    margin-top: 10px;
    line-height: 110%;
  }

  .title_body_container {
    position: relative;
    z-index: 1;
    display: flex; /* Establece el contenedor de enlaces como un flex container */
    flex-direction: column;
    justify-content: center; /* Centrar horizontalmente */
    margin-left: 198px;
    margin-right: 185px;
  }

  .paragraph {
    font-family: "Sansation Regular", sans-serif;
    color: #7e7f84;
    position: relative;
    z-index: 1;
  }

  .white_logo {
    position: absolute;
    top: 160px;
    width: 100%;
  }

  .secondTitle,
  .formTitle {
    line-height: 110%;
    position: relative;
    z-index: 1;
    color: #7e7f84;
    font-size: 46pt;
    font-weight: 400;
    margin-bottom: 20px;
    margin-top: 120px;
  }
  .formTitle {
    margin-top: 190px;
  }

  .bold {
    font-weight: bold;
  }

  .card {
    position: relative;
    display: flex; /* Establece el contenedor de enlaces como un flex container */
    flex-direction: column;
    justify-content: center; /* Centrar horizontalmente */
    border: 2px solid white;
    border-radius: 30px;
    margin: 30px;
    background-color: #f1f1f1;
    width: auto;
    height: 100%;
    margin-top: 15px;
    margin-bottom: 30px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
    padding: 3%;
  }

  .subtitle {
    color: #f49728;
    margin-bottom: 5px;
  }

  .paragraphCard {
    font-family: "Sansation Regular", sans-serif;
    color: #7e7f84;
  }

  .featuredCard {
    color: white;
    font-size: 1.5em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 2px solid white;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    padding: 70px;
    background-color: #f49728;
    width: auto;
    margin-top: 120px;
    margin-bottom: -70px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .button {
    position: relative;
    background-color: white;
    color: #f49728;
    border: none;
    width: 180px;
    border-radius: 60px;
    padding-top: 20px;
    padding-bottom: 20px;
    z-index: 1;
    top: 30px;
    transition: background-color 0.3s, cursor 0.3s; /* Transición de color y cursor */
    cursor: pointer; /* Cambio de cursor a mano */
  }
  .button:hover {
    color: #d88300;
    background-color: #f1f1f1;
  }

  .imagesContainer {
    margin-bottom: 100px;
  }

  .woman_image {
    border-radius: 40px;
    width: 60%;
    height: auto;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  }

  .coworkers_image {
    margin-top: 40px;
    position: absolute;
    border-radius: 40px;
    width: 45%;
    height: auto;
    z-index: 1;
    right: 0px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  }

  .secondCardsContainer {
    padding-left: 198px;
    margin-right: 185px;
    margin-bottom: 100px;
  }

  .thirdContainer {
    border-top-right-radius: 20%;
    background-color: white;
    height: 1870px;
  }

  .thirdTitle {
    color: white;
    font-size: 46pt;
    font-weight: 400;
    margin-bottom: 20px;
    margin-top: 80px;
  }

  .video {
    width: 768px;
    margin-top: 50px;
    margin-bottom: 100px;
  }

  .fourthContainer {
    margin-left: 197px;
    margin-right: 185px;
    margin-top: -100px;
    height: 100px;
  }
  .featuredContainer {
    background-color: #f49728;
    border-bottom-right-radius: 20%;
    border-top-right-radius: 20%;
  }

  .form {
    display: flex;
    flex-direction: column;
    margin-left: 107px;
  }

  .label {
    margin-bottom: 8px;
    margin-left: -100px;
    font-weight: bold;
    font-family: "Sansation Regular", sans-serif;
    color: #7e7f84;
  }

  input[type="text"] {
    font-family: "Sansation Regular", sans-serif;
    color: #7e7f84;
    padding: 8px;
    margin-bottom: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: border-color 0.3s ease;
  }

  input[type="text"]:focus {
    outline: none;
    border-color: #27b2f5;
  }

 select {
    font-family: "Sansation Regular", sans-serif;
    color: #7e7f84;
    padding: 8px;
    margin-bottom: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: border-color 0.3s ease;
    margin-left: -105px;
  }

  select:focus {
    outline: none;
    border-color: #27b2f5;
  }

  .formErrors {
    font-family: "Sansation Regular", sans-serif;
    color: red;
    margin-bottom: 10px;
    margin-top: -10px;
    font-size: 10pt;
    margin-left: -105px;
  }

  .text {
    font-family: "Sansation Regular", sans-serif;
    color: #7e7f84;
    font-size: 10pt;
    margin-top: 0px;
    margin-left: -100px;
  }

  .fileButton {
    margin-left: -100px;
  }

  .fileButton input[type="file"] {
    cursor: pointer;
    font-family: "Sansation Regular", sans-serif;
    margin-bottom: 20px;
    color: #d88300;
  }

  .buttonSubmit {
    margin-left: -105px;
    background-color: #f49728;
    color: white;
    border: none;
    width: 180px;
    border-radius: 60px;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 20px;
    transition: background-color 0.3s, cursor 0.3s;
    cursor: pointer;
  }
  .buttonSubmit:hover {
    background-color: #d88300;
  }
  .buttonSubmit[disabled] {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  .buttonSubmit[disabled]:hover {
    background-color: #cccccc;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .container {
    background-color: #f1f1f1;
    padding-bottom: 50px;
    padding-left: 100px;
  }

  .top_image {
    width: 100%;
    height: auto;
    margin-bottom: 50px;
  }

  .title_body_container {
    display: flex; /* Establece el contenedor de enlaces como un flex container */
    flex-direction: column;
    justify-content: center; /* Centrar horizontalmente */
    margin-left: 100px;
    margin-right: 100px;
  }

  .title {
    color: #f49728;
    font-size: 44pt;
    font-weight: 400;
    margin-bottom: 5px;
    margin-top: 0px;
  }

  .paragraph {
    font-family: "Sansation Regular", sans-serif;
    color: #7e7f84;
    position: relative;
    z-index: 1;
  }

  .white_logo {
    position: absolute;
    top: auto;
    width: 64%;
  }

  .secondTitle,
  .formTitle {
    position: relative;
    z-index: 1;
    color: #7e7f84;
    font-size: 36pt;
    font-weight: 400;
    margin-bottom: 20px;
    margin-top: 80px;
  }
  .formTitle {
    margin-top: 50px;
  }

  .bold {
    font-weight: bold;
  }

  .cardsContainer {
    margin-bottom: 100px;
  }

  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 2px solid white;
    border-radius: 30px;
    /* margin: 30px; */
    background-color: #f1f1f1;
    margin-top: 15px;
    margin-bottom: 20px;
    padding: 3%;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  }

  .subtitle {
    color: #f49728;
    margin-bottom: 5px;
    font-size: 16pt;
  }

  .paragraphCard {
    font-family: "Sansation Regular", sans-serif;
    color: #7e7f84;
  }

  .featuredCard {
    color: white;
    font-size: 16pt;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 2px solid white;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    padding: 10%;
    background-color: #f49728;
    margin-bottom: -70px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .button {
    position: relative;
    background-color: white;
    color: #f49728;
    border: none;
    width: 160px;
    border-radius: 60px;
    padding: 20px;
    z-index: 1;
    top: 20px;
    transition: background-color 0.3s, cursor 0.3s; /* Transición de color y cursor */
    cursor: pointer;
  }
  .button:hover {
    color: #d88300;
    background-color: #f1f1f1;
  }

  .imagesContainer {
    margin-bottom: 80px;
    padding-right: 3%;
  }

  .woman_image {
    border-radius: 40px;
    width: 60%;
    height: auto;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  }

  .coworkers_image {
    position: absolute;
    border-radius: 40px;
    z-index: 1;
    border-radius: 40px;
    width: 29%;
    height: auto;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  }

  .secondCardsContainer {
    margin-left: 100px;
    margin-right: 100px;
    margin-bottom: 100px;
  }

  .thirdContainer {
    margin-left: -100px;
    padding-bottom: 100px;
    padding-left: 100px;
    background-color: white;
    border-top-right-radius: 15%;
  }

  .thirdTitle {
    color: white;
    font-size: 36pt;
    font-weight: 400;
    margin-bottom: 20px;
    margin-top: 80px;
  }

  .video {
    width: auto;
    height: 360px;
    margin-top: 40px;
    margin-bottom: 70px;
  }

  .fourthContainer {
    margin-left: 100px;
    margin-right: 100px;
    margin-top: 100px;
  }
  .featuredContainer {
    background-color: #f49728;
    border-bottom-right-radius: 20%;
    border-top-right-radius: 20%;
  }

  .form {
    display: flex;
    flex-direction: column;
  }

  .label {
    margin-bottom: 8px;
    font-weight: bold;
    font-family: "Sansation Regular", sans-serif;
    color: #7e7f84;
  }

  input[type="text"] {
    font-family: "Sansation Regular", sans-serif;
    color: #7e7f84;
    padding: 8px;
    margin-bottom: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: border-color 0.3s ease;
  }

  input[type="text"]:focus {
    outline: none;
    border-color: #27b2f5;
  }

select {
    font-family: "Sansation Regular", sans-serif;
    color: #7e7f84;
    padding: 8px;
    margin-bottom: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: border-color 0.3s ease;
  }
  
  select:focus {
    outline: none;
    border-color: #27b2f5;
  }

  .formErrors {
    font-family: "Sansation Regular", sans-serif;
    color: red;
    margin-bottom: 10px;
    margin-top: -10px;
    font-size: 10pt;
  }

  .text {
    font-family: "Sansation Regular", sans-serif;
    color: #7e7f84;
    font-size: 10pt;
    margin-top: 0px;
  }

  .fileButton input[type="file"] {
    cursor: pointer;
    font-family: "Sansation Regular", sans-serif;
    margin-bottom: 20px;
    color: #d88300;
  }

  .buttonSubmit {
    background-color: #f49728;
    color: white;
    border: none;
    width: 180px;
    border-radius: 60px;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 20px;
    transition: background-color 0.3s, cursor 0.3s;
    cursor: pointer;
  }
  .buttonSubmit:hover {
    background-color: #d88300;
  }
  .buttonSubmit[disabled] {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  .buttonSubmit[disabled]:hover {
    background-color: #cccccc;
  }
}

@media screen and (max-width: 768px) {
  .top_image {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
    margin-top: 73px;
  }

  .container {
    background-color: #f1f1f1;
    padding-bottom: 50px;
    /* height: auto; */
  }

  .title_body_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 7%;
    padding-right: 7%;
  }

  .title {
    position: relative;
    z-index: 1;
    color: #f49728;
    margin-bottom: 5px;
    margin-top: 0px;
    font-size: 28pt;
    font-weight: 400;
  }

  .paragraph {
    font-family: "Sansation Regular", sans-serif;
    color: #7e7f84;
    position: relative;
    z-index: 1;
  }

  .white_logo {
    position: absolute;
    top: 440px;
    width: 80%;
  }

  .secondTitle,
  .formTitle {
    position: relative;
    z-index: 1;
    color: #7e7f84;
    font-size: 22pt;
    font-weight: 400;
    margin-bottom: 20px;
    margin-top: 40px;
  }
  .formTitle {
    margin-top: -20px;
  }

  .bold {
    font-weight: bold;
  }

  .cardsContainer {
    margin-bottom: 65px;
  }

  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 2px solid white;
    border-radius: 30px;
    background-color: #f1f1f1;
    margin-top: 15px;
    margin-bottom: 20px;
    padding: 3%;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  }

  .subtitle {
    color: #f49728;
    margin-bottom: 5px;
    font-size: 16pt;
  }

  .paragraphCard {
    font-family: "Sansation Regular", sans-serif;
    color: #7e7f84;
  }

  .featuredCard {
    color: white;
    font-size: 16pt;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 2px solid white;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    padding: 10%;
    background-color: #f49728;
    margin-bottom: -70px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .button {
    position: relative;
    background-color: white;
    color: #f49728;
    border: none;
    width: 150px;
    border-radius: 60px;
    padding: 20px;
    z-index: 1;
    transition: background-color 0.3s, cursor 0.3s;
    cursor: pointer;
  }
  .button:hover {
    color: #d88300;
    background-color: #f1f1f1;
  }

  .imagesContainer {
    display: flex;
    flex-direction: column;
  }

  .woman_image {
    border-radius: 40px;
    width: 100%;
    height: auto;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
    margin-bottom: 60px;
  }

  .coworkers_image {
    height: 0;
  }

  .secondCardsContainer {
    margin-left: 7%;
    margin-right: 7%;
    margin-bottom: 100px;
  }

  .thirdContainer {
    background-color: white;
  }

  .thirdTitle {
    color: white;
    font-size: 21pt;
    font-weight: 400;
    padding-bottom: 20px;
    margin-top: 50px;
  }

  .video {
    width: auto;
    margin-bottom: 50px;
  }

  .fourthContainer {
    margin-left: 7%;
    margin-right: 7%;
    margin-top: 100px;
  }

  .featuredContainer {
    background-color: #f49728;
  }

  .form {
    display: flex;
    flex-direction: column;
  }

  .label {
    margin-bottom: 8px;
    font-weight: bold;
    font-family: "Sansation Regular", sans-serif;
    color: #7e7f84;
  }

  input[type="text"] {
    font-family: "Sansation Regular", sans-serif;
    color: #7e7f84;
    padding: 8px;
    margin-bottom: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: border-color 0.3s ease;
  }

  input[type="text"]:focus {
    outline: none;
    border-color: #27b2f5;
  }

 select {
    font-family: "Sansation Regular", sans-serif;
    color: #7e7f84;
    padding: 8px;
    margin-bottom: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: border-color 0.3s ease;
  }
  
  select:focus {
    outline: none;
    border-color: #27b2f5;
  }

  .formErrors {
    font-family: "Sansation Regular", sans-serif;
    color: red;
    margin-bottom: 10px;
    margin-top: -10px;
    font-size: 10pt;
  }

  .text {
    font-family: "Sansation Regular", sans-serif;
    color: #7e7f84;
    font-size: 10pt;
    margin-top: 0px;
  }

  .fileButton {
    display: flex;
    /* align-items: center; */
  }

  .fileButton input[type="file"] {
    cursor: pointer;
    font-family: "Sansation Regular", sans-serif;
    margin-bottom: 20px;
    color: #d88300;
  }

  .buttonSubmit {
    background-color: #f49728;
    color: white;
    border: none;
    width: 180px;
    border-radius: 60px;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 20px;
    margin-bottom: 100px;
    transition: background-color 0.3s, cursor 0.3s;
    cursor: pointer;
  }
  .buttonSubmit:hover {
    background-color: #d88300;
  }
  .buttonSubmit[disabled] {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  .buttonSubmit[disabled]:hover {
    background-color: #cccccc;
  }
}
