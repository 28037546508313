@media screen and (min-width: 769px) {
  .container {
    background-color: #f1f1f1;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    width: 120px;
  }

  .linkContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
  }

  .link {
    margin-top: 20px;
    font-family: "Sansation Regular", sans-serif;
    font-size: 10pt;
    text-decoration: none;
    color: #7e7f84;
    margin-bottom: 30px;
  }
  .link:hover,
  .link:active,
  .link:focus {
    display: flex;
    justify-content: center;
    width: 100px;
    border-radius: 30px;
    background-color: lightgray;
    padding: 1%;
  }

  .linkContent {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .icon {
    font-weight: 600;
    font-size: 16pt;
    padding: 5px;
  }

  .logo {
    height: 35px;
  }
}

@media screen and (max-width: 768px) {
  .container {
    background-color: #f1f1f1;
    position: fixed;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    display: flex;
    align-items: center;
    width: 100%;
    height: 70px;
  }

  .linkContainer {
    display: flex;
    height: 70px;
    justify-content: space-between;
    width: 100%;
  }

  .linkContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .link {
    font-family: "Sansation Regular", sans-serif;
    font-size: 10pt;
    text-decoration: none;
    color: #7e7f84;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
  }
  .link:hover,
  .link:active,
  .link:focus {
    display: flex;
    justify-content: center;
    border-radius: 30px;
    background-color: lightgray;
    margin: 10px;
  }

  .icon {
    font-weight: 600;
    font-size: 11pt;
    padding: 5px;
  }

  .logo {
    height: 22px;
  }
}
